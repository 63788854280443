// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";  // Cambiado de firebase/auth a firebase/app
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZ_nCJWZBXGG064rhg-DkOYyoe1OsPDws",
  authDomain: "petalo9.firebaseapp.com",
  projectId: "petalo9",
  storageBucket: "petalo9.appspot.com",
  messagingSenderId: "494354395398",
  appId: "1:494354395398:web:4492063e3e347f541d2e87",
  measurementId: "G-3H02LKDFQM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);

export default app;
